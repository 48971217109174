<template>
    <div class="page-sa-email-builder">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <iframe
                        :src="sa_email_builder_url"

                        width="100%"
                        :height="height"
                        scrolling="no"

                        @load="onBuilderLoaded"

                        ref="builder"
                    ></iframe>
                </div>

                <div class="col-4">
                    <pre>{{ mjml }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            mjml: null,

            height: 600,
        }
    },

    mounted() {
        window.addEventListener('message', this.handlerMessage);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handlerMessage)
    },

    methods: {
        handlerMessage(event) {
            console.group('handlerMessage(event)')
            console.log('event', event)
            console.log('event.origin', event.origin)
            console.log('this.sa_email_builder_url', this.sa_email_builder_url)

            if (event.origin == this.sa_email_builder_url) {
                if (event.data && event.data.action) {
                    switch (event.data.action) {
                        case 'change-email': {
                            this.mjml = event.data.mjml
                        } break
                    }
                }
            }

            console.groupEnd()
        },

        onBuilderLoaded() {
            console.group('onBuilderLoaded()')
            console.log('contentWindow', this.$refs.builder.contentWindow)
            console.log('this.$refs.builder', this.$refs.builder)
            console.log('scrollHeight', this.$refs.builder.contentWindow.document.body.scrollHeight)
            console.log('clientHeight', this.$refs.builder.contentWindow.document.body.clientHeight)

            this.changeHeight()
            
            console.groupEnd()
        },
        
        changeHeight() {
            this.height = this.$refs.builder.contentWindow.document.body.scrollHeight
        },
    },

    computed: {
        ...mapGetters([
            'sa_email_builder_url',
        ])
    },
}
</script>

<style lang="scss">
// .page-sa-email-builder {}
</style>